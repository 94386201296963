<template>
  <div class="circleQgl_main">
    <pageHead class="messageMainView_head" title="圈子管理" :pageHead_fx="false" />
    <div class="main">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <div class="qztx_type_box" @click.prevent="van_jbxx">
            <van-cell title="圈子基本信息" class="qztx" is-link />
          </div>
        </van-cell-group>
        <van-cell-group inset>
          <div class="qztx_type_box" @click.prevent="van_qglsz">
            <van-cell title="圈管理设置" class="qztx" is-link />
          </div>
        </van-cell-group>
        <van-cell-group inset>
          <div class="qztx_type_box" @click.prevent="van_cygl">
            <van-cell title="成员管理" class="qztx" is-link />
          </div>
        </van-cell-group>
        <div class="button_cre">
          <van-button block style="color:#F42626;margin:8px 0 9px 0;" native-type="submit">
            解散圈子
          </van-button>
          <p class="button_line"></p>
        </div>
      </van-form>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import footers from '@/components/footer'

export default {
  name: 'circleQgl', // 圈子管理
  components: {
    pageHead,
    footers
  },
  methods: {
    // 确定解散
    onSubmit() {
      this.$dialog.confirm({
        title: '解散圈子',
        confirmButtonText: '确定',
        cancelButtonText: '再考虑下',
        message: '解散后无法还原，确定解散当前圈子？',
      });
    },
    // 圈子基本信息
    van_jbxx() {
      this.$router.push({name: "circleJbglsz"});
    },
    // 圈子管理员设置
    van_qglsz() {
      this.$router.push({name: "circleQglsz"});
    },
    // 成员管理
    van_cygl() {
      this.$router.push({name: "circleManagePeson"});
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 600px;
  overflow: scroll;

  p {
    margin: 0;
    padding: 0;
  }

  ::v-deep{
    .van-picker__columns {
      height: 248px !important;
    }

    .van-field__word-limit {
      font-size: 14px;
      color: rgba(109, 114, 120, 0.50);
    }

    .van-dialog {
      overflow: visible !important;
    }

    .van-cell {
      height: 56px;
      padding: 18px 20.75px 0 16px;
    }

    .van-picker__cancel {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.60);
    }

    .van-picker__title {
      font-size: 16px;
      color: #000000;
    }

    .van-picker__confirm {
      font-size: 16px;
      color: #000000;
    }

    .van-uploader__preview-image {
      width: 46px;
      height: 46px;
      border-radius: 50%;

      img {
        width: 46px;
        height: 46px;
      }
    }

    .van-uploader__upload {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      //  margin-left: 25px;
      .van-uploader__input {
        width: 46px;
        height: 46px;
      }

      .van-icon-photograph::before {
        content: '+';
        top: -4px;
        position: relative;
      }
    }

    .van-field_box2 {
      .van-cell {
        height: 118px;
      }

      input {
        font-size: 14px;
      }

      input::placeholder {
        color: rgba(109, 114, 120, 0.50);
      }
    }

    .van-uploader__preview-delete {
      border-radius: 50%;
    }
  }

  .van_cell_read {
    span {
      font-size: 12px;
      color: #323233;
    }
  }

  .myCreateCircleXzShow {
    ::v-deep{
      .van-dialog__header {
        padding: 0;
      }

      .van-dialog__content {
        justify-content: center;
        text-align: center;
        margin-top: 60px;
      }
    }

    .qzcrxz {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.90);
      margin-top: 12px;
    }

    .show_til {
      width: 166px;
      height: 120px;
      display: inline-block;
      background: url('/images/circle/createNotice.png') no-repeat;
      background-size: 100%;
      position: absolute;
      top: -66px;
      left: 76px;
    }

    .qzcrFdtil {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.40);
      margin-top: 6px;
      margin-bottom: 10px;
    }

    .xzCont {
      width: 280px;
      height: 186px;
      background: #fafafa;
      border-radius: 8px;
      margin: 0 auto;
      font-size: 14px;
      padding-top: 18px;
    }
  }

  ::v-deep{
    .van-dialog__confirm {
      width: 149px;
      height: 40px;
      margin-top: 20px;
      margin-bottom: 32px;
      background: #fe5800;
      border-radius: 200px;
      font-size: 16px;
      text-align: CENTER;
      color: #ffffff;
      text-shadow: 0px 2px 20px 0px rgba(224, 82, 6, 0.20);
    }

    .van-button::before {
      width: 149px;
      height: 40px;
    }

    .van-dialog__footer {
      margin: 0 90px;
    }

    .van-field__word-limit {
      position: absolute;
      right: 0;
      bottom: 15px;
    }
  }
}
</style>
