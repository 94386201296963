<template>
  <div class="footer">
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: 'footer'
}
</script>

<style lang="scss" scoped>
.footer {
  width: 375px;
  height: 34px;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;

  .line {
    margin-top: 20px;
    width: 134px;
    height: 5px;
    opacity: 0.6;
    background: #333333;
    border-radius: 3px;
  }
}
</style>
